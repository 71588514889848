import { IsoCountryCode } from '@rbilabs/fhs-common';

export const ASCENDING = 'asc';
export const DESCENDING = 'desc';

export const SAFE_DENOMINATOR_ZERO = 0.00000000000001;

export const DAYS_OF_THE_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const DELIVERY_STATUS_MAPPING = {
  DRIVER_UNASSIGNED: 'Driver Unassigned',
  DRIVER_ASSIGNED: 'Driver Assigned',
  DRIVER_AT_CUSTOMER: 'Driver At Customer',
  DRIVER_AT_STORE: 'Driver At Store',
  DRIVER_STARTING: 'Driver Starting',
  ORDER_ABANDONED: 'Order Abandoned',
  ORDER_CANCELLED: 'Order Cancelled',
  ORDER_CREATED: 'Order Created',
  ORDER_DROPPED_OFF: 'Order Dropped Off',
  ORDER_ERROR: 'Order Error',
  ORDER_PICKED_UP: 'Order Picked Up',
  ORDER_TIMEOUT: 'Order Timeout',
  QUOTE_ERROR: 'Quote Error',
  QUOTE_REQUESTED: 'Quote Requested',
  QUOTE_SUCCESSFUL: 'Quote Successful',
  QUOTE_UNAVAILABLE: 'Quote Unavailable',
};

export const DISCOUNTS_MAPPING = {
  REWARDS_DISCOUNTS: 'Rewards Discount',
};

export const COMBO = 'Combo';
export const ITEM = 'Item';
export const ITEM_OPTION_MODIFIER = 'ItemOptionModifier';
export const OFFER_COMBO = 'OfferCombo';
export const OFFER_ITEM = 'OfferItem';
export const OFFER_DISCOUNT = 'offerDiscount';

export const DISPLAY_TYPES = [COMBO, OFFER_COMBO, ITEM, OFFER_ITEM];
export const COMBO_TYPES = [COMBO, OFFER_COMBO];
export const OFFER_TYPES = [OFFER_COMBO, OFFER_ITEM];

export const NON_FOOD = 'NON FOOD';

export const MIN_COUPON_ASSIGNMENT_LIMIT = 1;
export const MAX_COUPON_ASSIGNMENT_LIMIT = 10;
export const TEXT_AREA_CHAR_LIMIT = 250;
export const MAX_EXCEEDED_ERROR = 'Refund Quantity cannot exceed Quantity';
export const MAX_LENGTH_ERROR = `This field must be less than ${TEXT_AREA_CHAR_LIMIT} characters`;
export const VALUE_REQUIRED_ERROR = 'This field is required';

export enum REFUND_OPTIONS {
  CUSTOMER_NOT_FOUND = 'Customer Was Not Found/Unavailable',
  CUSTOMER_OVERCHARGED = 'Customer Was Overcharged',
  CUSTOMER_REQUESTED_CANCEL_ORDER = 'Customer Requested Cancel Order',
  ITEMS_DAMAGED = 'Items Were Damaged',
  MOBILE_ORDERING_TECHNICAL_ISSUE = 'Mobile Ordering Technical Issue',
  ORDER_NEVER_ARRIVED = 'Order Never Arrived',
  ORDER_WAS_DAMAGED = 'Order Was Damaged',
  POOR_DELIVERY_EXPERIENCE = 'Poor Delivery Experience',
  TOO_MANY_REFUNDS = 'Too Many Refunds',
  //Restaurant Reasons
  RESTAURANT_CLOSED = 'Restaurant Was Closed',
  RESTAURANT_COULDNT_FIND_ORDER = "Restaurant Couldn't Find Order",
  MISSING_OR_INCORRECT_ITEM = 'Restaurant At Fault - Missing Or Incorrect Item', //Pull These Out?
  INCORRECT_ORDER = 'Restaurant At Fault - Order Is Wrong',
  ORDER_ARRIVED_LATE = 'Restaurant At Fault - Order Arrived Late',
  //Uber Reasons
  DRIVER_CANCELLED = 'Uber At Fault - Driver Canceled',
  DRIVER_DAMAGED = 'Uber At Fault - Items Were Damaged',
  DRIVER_DAMAGED_ORDER = 'Uber At Fault - Order Was Damaged',
  DRIVER_LATE = 'Uber At Fault - Order Arrived Late',
  DRIVER_MISSED_ITEMS = 'Uber At Fault - Missing Items',
  DRIVER_NEVER_ARRIVED = 'Uber At Fault - Order Never Arrived',
  DRIVER_NEVER_PICKED_UP = 'Uber At Fault - Driver Never Picked Up',
  NO_DRIVER_FOUND = 'Uber At Fault - No Courier Found',
  WRONG_ORDER = 'Uber At Fault - Wrong Order Delivered',
}

const INSERT_SUCCESSFUL = 'Insert Successful';

export const ORDER_STATUS_MAPPING = {
  CATERING_DECLINED: 'Catering Declined',
  CATERING_ERROR: 'Catering Error',
  CATERING_PLACED: 'Catering Placed',
  CATERING_SUCCESSFUL: 'Catering Successful',
  INSERT_ERROR: 'Insert Error',
  INSERT_REQUESTED: 'Insert Requested',
  INSERT_SUCCESSFUL,
  KOUNT_DENIAL: 'Kount Denial',
  PAYMENT_ERROR: 'Payment Error',
  PAYMENT_REQUESTED: 'Payment Requested',
  PAYMENT_REQUIRED: 'Payment Required',
  PAYMENT_SUCCESSFUL: 'Payment Successful',
  PRICE_ERROR: 'Price Error',
  PRICE_REQUESTED: 'Price Requested',
  PRICE_SUCCESSFUL: 'Price Successful',
  REFUND_ERROR: 'Refund Error',
  REFUND_REQUESTED: 'Refund Requested',
  REFUND_SUCCESSFUL: 'Refund Successful',
  UPDATE_ERROR: 'Update Error',
  UPDATE_REQUESTED: 'Update Requested',
  UPDATE_SUCCESSFUL: 'Update Successful',
  VALIDATION_ERROR: 'Validation Error',
};

export const DEFAULT_INACTIVE_STATUSES = ['PRICE_REQUESTED', 'PRICE_SUCCESSFUL', 'PRICE_ERROR'];

export const REFUND_END_STATUSES = ['REFUND_SUCCESSFUL', 'REFUND_ERROR'];

export const GIFT_CARD_TRANSACTION_TYPE_MAPPING = {
  'Redemption No NSF': 'Redemption No NSF - Scan to Pay',
  'Normal Redemption, no NSF': 'Normal Redemption, no NSF - MO&P',
  'Unknown Code returned': 'Unknown Code - MO&P',
};

export const EMPTYDATA = '- - -';

export const PAYMENT_STATUS_MAPPING = {
  AUTHORIZATION_REQUESTED: 'Authorization Requested',
  AUTHORIZATION_ERROR: 'Authorization Error',
  AUTHORIZATION_SUCCESSFUL: 'Authorization Successful',
  CAPTURE_REQUESTED: 'Capture Requested',
  CAPTURE_ERROR: 'Capture Error',
  CAPTURE_SUCCESSFUL: 'Capture Successful',
  CANCEL_REQUESTED: 'Cancel Requested',
  CANCEL_ERROR: 'Cancel Error',
  CANCEL_SUCCESSFUL: 'Cancel Successful',
  PAYMENT_REQUESTED: 'Payment Requested',
  PAYMENT_REQUIRED: 'Payment Required',
  PAYMENT_ERROR: 'Payment Error',
  PAYMENT_SUCCESSFUL: 'Payment Successful',
  REFUND_REQUESTED: 'Refund Requested',
  REFUND_ERROR: 'Refund Error',
  REFUND_SUCCESSFUL: 'Refund Successful',
  VOID_REQUESTED: 'Void Requested',
  VOID_ERROR: 'Void Error',
  VOID_SUCCESSFUL: 'Void Successful',
};

export const REFUNDABLE_PAYMENT_V1_STATUSES = [
  'AUTHORIZATION_SUCCESSFUL',
  'PAYMENT_SUCCESSFUL',
  'CAPTURE_SUCCESSFUL',
];

export const SUPPORT_AGENT_EMAIL_REFUND_STATUS_MAPPING = {
  'auto-refund@rbictg.com': 'Auto',
};

export const REFUNDABLE_NON_PAYMENT_V1_ORDER_STATUSES = [
  'INSERT_SUCCESSFUL',
  'PAYMENT_SUCCESSFUL',
  'UPDATE_ERROR',
  'UPDATE_REQUESTED',
  'UPDATE_SUCCESSFUL',
];

export const REFUND_ERROR_PAYMENT_V1_STATUSES = ['REFUND_ERROR', 'VOID_ERROR'];

export const REFUND_SUCCESS_PAYMENT_V1_STATUSES = ['REFUND_SUCCESSFUL', 'VOID_SUCCESSFUL'];

export const REFUND_ERROR_NON_PAYMENT_V1_ORDER_STATUSES = ['REFUND_ERROR'];

export const REFUND_SUCCESS_NON_PAYMENT_V1_ORDER_STATUSES = ['REFUND_SUCCESSFUL'];

export const VAT_EXCLUDED_COUNTRIES = [IsoCountryCode.DEU, IsoCountryCode.GBR, IsoCountryCode.CHE];

export const LAST_VIEWED_RELEASE_NOTE_DATE = 'lastViewedReleaseNote';

export const OFFERS_V2_LAUNCH_DARKLY_FLAG_NAME = 'enable-loyalty-standard-offers';

export const REMEDIATION_REASONS_ID = 'remediation-reasons';

export const REMEDIATION_TYPES_MAPPING = {
  NONE: 'NONE',
  OFFER: 'OFFER',
  OFFERV1: 'OFFERV1',
  OFFERV2: 'OFFERV2',
  POINTS: 'POINTS',
  REFUND: 'REFUND',
};

export const REMEDIATION_TYPES_LABELS = {
  OFFERV1: 'V1 Offer',
  OFFERV2: 'V2 Offer',
  POINTS: 'Point',
  REFUND: 'Refund',
};

export const REMEDIATION_TYPES_MAPPING_COMMENTS = {
  NONE: 'COMMENTS',
  OFFER: 'OFFER',
  POINTS: 'POINTS',
  REFUND: 'REFUND',
  ROLLS: 'ROLLS',
};

export const POSSIBLE_PAYMENT_STATUS_FOR_REFUND = [
  'REFUND_ERROR',
  'REFUND_REQUESTED',
  'REFUND_SUCCESSFUL',
  'VOID_ERROR',
  'VOID_REQUESTED',
  'VOID_SUCCESSFUL',
];

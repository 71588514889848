import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Switch,
  TextField,
  Chip,
  Collapse,
  Divider,
} from '@material-ui/core';
import { startCase } from 'lodash-es';

import ExpandRowButton from 'components/expand-row-button';
import { shortDate } from 'components/utils';

import { useStyles, useChipStyles, useFilterDetailsStyles } from './styles';
import {
  FilterContainerProps,
  CheckboxFilterProps,
  FilterIndices,
  DateFilterProps,
  TextFilterProps,
} from './types';

//Since we setup type inheritance, you can just pass the deconstructed props down.
const CheckboxesFilter = (props: CheckboxFilterProps) => {
  const classes = useStyles();
  return (
    <>
      <FilterLabel {...props}>
        <SelectAll {...props} />
      </FilterLabel>

      <CheckboxesChips {...props} />
      <FilterDetails2 {...props}>
        <Grid container spacing={0}>
          {props.options.map((opt, index) => {
            return (
              <Grid item key={index} xl={4} lg={6} md={4} xs={12} className={classes.gridItem}>
                <FormControlLabel
                  control={<Checkbox color="primary" size="small" disableRipple />}
                  checked={opt}
                  onChange={() =>
                    props.setOptionsActivated(
                      props.options.map((option, optionIndex) =>
                        optionIndex === index ? !option : option,
                      ),
                    )
                  }
                  label={startCase(props.checkboxNames[index].toLowerCase())}
                />
              </Grid>
            );
          })}
        </Grid>
      </FilterDetails2>
      <Divider />
    </>
  );
};

const DateFilter = (props: DateFilterProps) => {
  const classes = useStyles();
  return (
    <>
      <FilterLabel {...props} />
      <DateChips {...props} />
      <FilterDetails2 {...props}>
        <Grid container spacing={2}>
          <Grid item xs={6} className={classes.gridItem}>
            <TextField
              id="from-date"
              label="From"
              type="date"
              value={props.startDate}
              onChange={(event) => props.setStartDate(event.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6} className={classes.gridItem}>
            <TextField
              id="to-date"
              label="To"
              type="date"
              value={props.endDate}
              onChange={(event) => props.setEndDate(event.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </FilterDetails2>
      <Divider />
    </>
  );
};

const TextFilter = (props: TextFilterProps) => {
  const classes = useStyles();
  return (
    <>
      <FilterLabel {...props}>
        <TextChip {...props} />
      </FilterLabel>
      <FilterDetails2 {...props}>
        <Grid item xs={8} className={classes.gridItem}>
          <TextField
            label={props.label}
            value={props.text}
            onChange={(event) => props.setText(event.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </FilterDetails2>
      <Divider />
    </>
  );
};

export const FilterLabel = ({
  label,
  filterIndex,
  expandedField,
  setExpandedField,
  children,
}: FilterContainerProps) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <ExpandRowButton
        className={classes.expandButton}
        expanded={expandedField === filterIndex}
        onClick={() =>
          setExpandedField(expandedField === filterIndex ? FilterIndices.None : filterIndex)
        }
      />
      {
        <Typography
          variant="subtitle1"
          className={classes.headerText}
          onClick={() =>
            setExpandedField(expandedField === filterIndex ? FilterIndices.None : filterIndex)
          }
        >
          {label}
        </Typography>
      }
      {children}
    </div>
  );
};

const FilterDetails2 = ({ children, expandedField, filterIndex }: FilterContainerProps) => {
  const classes = useFilterDetailsStyles();
  return (
    <Collapse in={expandedField === filterIndex}>
      <div className={classes.filterDetails}> {children}</div>
    </Collapse>
  );
};

const CheckboxesChips = ({
  expandedField,
  filterIndex,
  checkboxNames,
  options,
  setExpandedField,
  setOptionsActivated,
}: CheckboxFilterProps) => {
  const classes = useChipStyles();
  const MAX_PILL_DISPLAY = 3;
  const countSelected = options.filter((option) => option).length;

  return (
    //Show component if we aren't expanded or if there is nothing or if all are selected
    <Collapse
      in={
        expandedField !== filterIndex &&
        countSelected !== 0 &&
        countSelected !== checkboxNames.length
      }
    >
      <div className={classes.chips}>
        {countSelected <= MAX_PILL_DISPLAY ? (
          options.map((option, index) => {
            return (
              option && (
                <Chip
                  size="medium"
                  variant="outlined"
                  key={index}
                  // Is this possibly a redundant lowercase
                  label={startCase(`${checkboxNames[index]}`.toLowerCase())}
                  className={classes.chip}
                  onClick={() => {
                    setExpandedField(filterIndex); //This shouldn't need to hide since it won't render if not expanded
                  }}
                  onDelete={() => {
                    //Create an array to map the checkboxes that should be checked and the chips that should be displayed
                    const enabledOptions = options.map((option, optionIndex) =>
                      optionIndex === index ? !option : option,
                    );
                    setOptionsActivated(
                      enabledOptions.every((_) => _ === false)
                        ? options.map((_) => true) // if all the chips are hidden, we want to select all the checkboxes, so we show all the records
                        : enabledOptions, // if not all the chips are hidden,we just check those that are matched with the mapping array
                    );
                  }}
                />
              )
            );
          })
        ) : (
          <Chip
            size="medium"
            variant="outlined"
            label={`${MAX_PILL_DISPLAY}+ filters...`}
            className={classes.chip}
            onClick={() => {
              setExpandedField(filterIndex); //This shouldn't need to hide since it won't render if not expanded
            }}
          />
        )}
      </div>
    </Collapse>
  );
};

const DateChips = ({
  expandedField,
  filterIndex,
  setExpandedField,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: DateFilterProps) => {
  const classes = useChipStyles();

  return (
    <Collapse in={expandedField !== filterIndex && !!startDate && !!endDate}>
      <div className={classes.chips}>
        <Chip
          size="medium"
          variant="outlined"
          label={`From: ${shortDate(new Date(startDate.replace(/-/g, '/')))}`}
          className={classes.chip}
          onClick={() => {
            setExpandedField(filterIndex);
          }}
          onDelete={() => setStartDate('')}
        />

        <Chip
          size="medium"
          variant="outlined"
          label={`To: ${shortDate(new Date(endDate.replace(/-/g, '/')))}`}
          className={classes.chip}
          onClick={() => {
            setExpandedField(filterIndex);
          }}
          onDelete={() => setEndDate('')}
        />
      </div>
    </Collapse>
  );
};

const TextChip = ({
  expandedField,
  filterIndex,
  setExpandedField,
  text,
  setText,
}: TextFilterProps) => {
  const classes = useChipStyles();

  return (
    <Collapse in={expandedField !== filterIndex && !!text}>
      <div className={classes.chips}>
        <Chip
          size="medium"
          variant="outlined"
          label={`${text}`}
          className={classes.chip}
          onClick={() => {
            setExpandedField(filterIndex);
          }}
          onDelete={() => setText('')}
        />
      </div>
    </Collapse>
  );
};

const SelectAll = ({
  options,
  setOptionsActivated,
  filterIndex,
  expandedField,
}: CheckboxFilterProps) => {
  const classes = useStyles();
  return (
    <Collapse in={expandedField === filterIndex}>
      <FormControlLabel
        className={classes.switch}
        control={
          <Switch
            size="medium"
            color="primary"
            checked={options.every((option) => option)}
            onChange={() => {
              const swapTo = options.every((value) => value);
              setOptionsActivated(options.map((_) => !swapTo));
            }}
          />
        }
        label="All"
      />
    </Collapse>
  );
};

export { CheckboxesFilter, DateFilter, TextFilter };

import { useState, useEffect } from 'react';

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Tooltip,
} from '@material-ui/core';
import { Receipt } from '@material-ui/icons';
import clsx from 'clsx';

import DummyText from 'components/dummy-text';

import { useStyles } from './styles';
import { SummaryProps, actionType, annotationType } from './types';

const Summary = ({
  actions,
  annotations,
  content,
  header,
  icon,
  isLoading,
  subheader,
  preventBlinking,
}: SummaryProps) => {
  const classes = useStyles();

  const [blink, setBlink] = useState(false);
  useEffect(() => {
    setBlink(true);
    const timer = setTimeout(() => {
      setBlink(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [subheader]);

  const renderAnnotations = (_annotations?: Array<annotationType>) => {
    return (
      <>
        {_annotations
          ? _annotations.map((annotation: annotationType, index: number) => (
              <div
                className={classes.chipsWrapper}
                key={index}
                {...(isLoading ? null : { 'data-testid': `annotations_${index}` })}
              >
                <Tooltip title={annotation?.tooltipText || ''}>
                  <Chip
                    label={isLoading ? <DummyText width={100} /> : annotation?.label}
                    avatar={
                      isLoading ? undefined : (
                        <Avatar className={classes.avatar}>
                          <Receipt
                            fontSize="small"
                            className={clsx(classes.icon, classes.avatarText)}
                          />
                        </Avatar>
                      )
                    }
                  />
                </Tooltip>
              </div>
            ))
          : null}
      </>
    );
  };
  const renderActions = (_actions?: Array<actionType>) => {
    return isLoading ? (
      <DummyText width={120} />
    ) : (
      <>
        {_actions
          ? _actions.map((action: actionType, index: number) => (
              <Button
                color="primary"
                disabled={isLoading || action.disabled}
                disableElevation
                key={index}
                onClick={action.onClick}
                size="small"
                variant="contained"
              >
                {action?.label}
              </Button>
            ))
          : null}
      </>
    );
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={header}
        subheader={
          isLoading ? (
            <DummyText width={120} />
          ) : (
            <span className={blink === true && !preventBlinking ? classes.blink : classes.default}>
              {subheader}
            </span>
          )
        }
        avatar={icon}
        titleTypographyProps={{ variant: 'h5' }}
        action={renderAnnotations(annotations)}
      />
      <CardContent>{isLoading ? <DummyText /> : content ?? null}</CardContent>
      {!!actions ? (
        <CardActions className={classes.actions}>{renderActions(actions)}</CardActions>
      ) : null}
    </Card>
  );
};

export { Summary };

import gql from 'graphql-tag';

export const IssueCouponToken = gql`
  mutation IssueCouponToUser(
    $count: Int
    $comments: String
    $couponId: ID!
    $expiresOn: String
    $reason: String
    $refundedRbiOrderId: String
    $userId: ID!
  ) {
    issueCouponToUser(
      count: $count
      comments: $comments
      couponId: $couponId
      expiresOn: $expiresOn
      reason: $reason
      refundedRbiOrderId: $refundedRbiOrderId
      userId: $userId
    ) {
      count
      tokens {
        couponId
        userId
      }
    }
  }
`;

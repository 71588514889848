import { useCallback, useState } from 'react';

import { useQuery } from '@apollo/react-hooks';

import { universalSearch } from 'remote/queries';
import { ISearchResult } from 'types/search';

import { SearchItem } from '../../../services/graphql/src/generated/graphql';

export { searchResultDisplayMap } from './search-result-display-map';

export const useUniversalSearch = (searchTerm: string): ISearchResult & { loading: boolean } => {
  const { data, loading } = useQuery(universalSearch, {
    variables: { searchTerm },
    skip: searchTerm.length < 3,
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });

  const { results, hits } = data?.Search ?? { results: [], hits: 0, succeeded: false };
  const normalizedResults = results.map((result: any): SearchItem => {
    return {
      ...result,
      id: result.__typename === 'CustomerDetails' ? result.customerId : result.id,
    };
  });
  return { loading, results: normalizedResults, hits };
};

export const useUniversalSearchFocus = (): {
  focused: boolean;
  setFocused: Function;
} => {
  const [focused, setFocused] = useState(false);
  const handleFocused = useCallback((isFocused) => {
    setFocused(isFocused);
  }, []);

  return { focused, setFocused: handleFocused };
};

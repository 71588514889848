import { FC, useCallback, useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import {
  Drawer,
  AppBar,
  Badge,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Collapse,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
  Menu,
  ChevronLeft,
  ChevronRight,
  ExitToApp,
  Warning,
  ExpandLess,
  ExpandMore,
  Language,
  Home,
  Note,
  Today,
} from '@material-ui/icons';
import { ListItemButton } from '@mui/material';
import { navigate, Link } from '@reach/router';
import clsx from 'clsx';

import AuthRequired from 'components/auth/auth-required';
import Logo from 'components/logos';
import { ReleaseNote } from 'components/release-notes/types';
import { longDateFromYYYYMMDDString } from 'components/utils';
import { LAST_VIEWED_RELEASE_NOTE_DATE } from 'components/utils/constants';
import brandConfigs from 'config/brand-resolution';
import { GetCustomerOffersVersion, GetReleaseNotes } from 'remote/queries';
import { useAuthContext } from 'state/auth';
import { useLocationContext } from 'state/location';
import { useParamsContext } from 'state/params';
import { routes, isRouteSelected, expandPath } from 'utils/routing';

import { useStyles } from './layout.styles';
import { UniversalSearch } from './universal-search';

const Layout: FC = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { email, signOut, roles } = useAuthContext();
  const { location } = useLocationContext();
  const { params } = useParamsContext();

  const customerId = params.customerId;
  const { data: customerOffersVersionData, loading: customerOffersVersionLoading } = useQuery(
    GetCustomerOffersVersion,
    {
      variables: { customerId },
      skip: !customerId,
    },
  );

  const offersVersion = customerOffersVersionData?.Customer?.offersVersion ?? 'v1';

  const [drawerOpen, setDrawerOpen] = useState(true);
  const [releaseNotesOpen, setReleaseNotesOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleReleaseNotesClick = () => {
    setReleaseNotesOpen(!releaseNotesOpen);
  };

  const handleUserClick = () => {
    setUserOpen(!userOpen);
  };

  const handleSignOut = useCallback(async () => {
    await signOut();
    navigate(routes.signIn);
  }, [signOut]);

  const { data: releaseNotesData } = useQuery(GetReleaseNotes, {
    variables: { limit: 10, offset: 0 },
  });

  const lastViewedReleaseDate = localStorage.getItem(LAST_VIEWED_RELEASE_NOTE_DATE) ?? '';
  const unviewedReleaseNotes = !!releaseNotesData?.ReleaseNotes
    ? releaseNotesData?.ReleaseNotes.filter((note: ReleaseNote) => {
        return !!note.releaseDate && note.releaseDate > lastViewedReleaseDate;
      })
    : [];

  const numUnviewedReleaseNotes = unviewedReleaseNotes.length;

  return (
    <div className={classes.root}>
      <AuthRequired>
        <>
          <AppBar
            position="fixed"
            variant="outlined"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: drawerOpen,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: drawerOpen,
                })}
              >
                <Menu />
              </IconButton>

              <Link to={routes.default} className={classes.logo}>
                <Logo />
              </Link>
              <UniversalSearch />
            </Toolbar>
          </AppBar>

          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
              </IconButton>
            </div>

            <Divider />

            <List>
              <ListItemButton
                className={classes.menuButton}
                onClick={() => {
                  navigate('/');
                }}
              >
                <ListItemIcon>
                  <Home />
                </ListItemIcon>
                <ListItemText primary={'Home'} />
              </ListItemButton>
            </List>

            <Divider />

            <List>
              {brandConfigs.links.map(({ name, route, Icon }) => {
                const isOffersRoute = route.startsWith('/offers');
                const isOffersRouteAndCustomerOffersVersionLoading =
                  isOffersRoute && customerOffersVersionLoading;
                const offersRoute = `/offers-${offersVersion}`;
                const resolvedRoute = isOffersRouteAndCustomerOffersVersionLoading
                  ? routes.offersLD
                  : isOffersRoute
                  ? offersRoute
                  : route;
                const targetPath = expandPath(resolvedRoute, params);
                return (
                  <ListItemButton
                    key={name}
                    selected={isRouteSelected(route, location.pathname)}
                    onClick={() => {
                      navigate(targetPath);
                    }}
                  >
                    <ListItemIcon>{Icon}</ListItemIcon>
                    <ListItemText primary={name} />
                  </ListItemButton>
                );
              })}
            </List>

            <Divider />

            <List>
              <ListItemButton onClick={handleUserClick} disabled={!drawerOpen}>
                <ListItemIcon>
                  <Avatar className={classes.avatar}>{email?.[0]}</Avatar>
                </ListItemIcon>
                <ListItemText className={classes.email} primary={email?.split('@')?.[0]} />
                {userOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={userOpen && drawerOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {(roles as string[]).map((role) => {
                    return (
                      <ListItem className={classes.nested} key={role}>
                        <ListItemIcon>
                          <Language />
                        </ListItemIcon>
                        <ListItemText primary={role} />
                      </ListItem>
                    );
                  })}
                  {roles.length === 0 && (
                    <ListItem className={classes.nested}>
                      <ListItemIcon>
                        <Warning />
                      </ListItemIcon>
                      <ListItemText primary={'No roles assigned'} />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </List>

            <Divider />

            <List>
              <ListItemButton onClick={handleReleaseNotesClick} disabled={!drawerOpen}>
                <ListItemIcon>
                  <Badge
                    overlap="rectangular"
                    badgeContent={numUnviewedReleaseNotes}
                    color="primary"
                  >
                    <Note />
                  </Badge>
                </ListItemIcon>
                <ListItemText primary={'Release Notes'} />
                {!!releaseNotesData?.ReleaseNotes.length ? (
                  releaseNotesOpen ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : null}
              </ListItemButton>
              <Collapse in={releaseNotesOpen && drawerOpen}>
                <List component="div" disablePadding>
                  {releaseNotesData?.ReleaseNotes?.slice(0, 3).map(
                    (note: ReleaseNote, index: number) => {
                      return (
                        <ListItemButton
                          className={classes.nested}
                          key={index}
                          onClick={() => {
                            navigate(`${routes.releaseNotes}/${note?.releaseDate}`);
                          }}
                        >
                          <ListItemIcon>
                            <Today />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              !!note.releaseDate && longDateFromYYYYMMDDString(note.releaseDate)
                            }
                          />
                        </ListItemButton>
                      );
                    },
                  ) ?? null}
                </List>
              </Collapse>
            </List>

            <Divider />

            <List>
              <ListItemButton onClick={handleSignOut}>
                <ListItemIcon>
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText primary={'Sign Out'} />
              </ListItemButton>
            </List>

            <Divider />
          </Drawer>
        </>
      </AuthRequired>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

export default Layout;

import React, { FC } from 'react';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider as MUThemeProvider } from '@material-ui/styles';

import brandConfigs from 'config/brand-resolution';

import {
  WHITE,
  BACKGROUND_ACCENT,
  BACKGROUND_SECONDARY,
  GREY,
  LIGHT_GREY,
  SECONDARY_GREY,
  DARK_GREY,
  ACTIVE_GREY,
  PRIMARY_ACCENT,
  PRIMARY_BUTTON,
  LIGHT_RED,
  SECONDARY_RED,
  DARK_RED,
  LIGHT_GREEN,
  DARK_BLUE,
} from './colors';

export const palette = {
  background: {
    surface: WHITE,
    secondary: BACKGROUND_SECONDARY,
    accent: BACKGROUND_ACCENT,
  },
  green: {
    accent: LIGHT_GREEN,
  },
  grey: {
    accent: LIGHT_GREY,
    secondary: SECONDARY_GREY,
    main: GREY,
    raised: DARK_GREY,
    active: ACTIVE_GREY,
  },
  primary: {
    accent: PRIMARY_ACCENT,
    main: PRIMARY_BUTTON,
    raised: DARK_BLUE,
    contrastText: WHITE,
  },
  alert: {
    accent: LIGHT_RED,
    secondary: SECONDARY_RED,
    main: DARK_RED,
    raised: DARK_RED,
  },
};

const brandPalette = {
  primary: { main: brandConfigs.theme.brandColor },
};

const theme = createTheme({ palette: brandPalette });

export const ThemeProvider: FC = ({ children }) => (
  <MUThemeProvider theme={theme}>{children}</MUThemeProvider>
);

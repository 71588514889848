import { from, split, Operation } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { isNil } from 'lodash-es';

import { sanityFetch } from './sanity-fetch';
import { stripTypenameLink } from './strip-typename';
import { withAuthToken } from './with-auth-token';
import { withHeaders } from './with-headers';
import { GQL_URL } from '../../../constants';

// TODO - MIGRATE THESE LINKS TO A FRONTEND PACKAGE FOR BOTH MAIN / ADMIN APP TO SHARE

const isSanityRequest = (context?: Record<string, any>) => !isNil(context && context.uri);

const operationIsSanityRequest = (operation: Operation) => isSanityRequest(operation.getContext());

const fetchOptions = { referrerPolicy: 'no-referrer' };

const sanityHttpLink = from([
  new RetryLink({ attempts: { max: 3 } }),
  // sanity requests have the uri set in context
  createHttpLink({ credentials: 'omit', fetch: sanityFetch, fetchOptions }),
]);

const lambdaHttpLink = from([withAuthToken, createHttpLink({ uri: GQL_URL, credentials: 'omit' })]);

const httpSplit = split(operationIsSanityRequest, sanityHttpLink, lambdaHttpLink);

export const link = from([
  withHeaders({ 'content-type': 'application/json' }),
  stripTypenameLink,
  httpSplit,
]);

// could just use `link` but this allows future customization to pass in language and region before each query fires
export const getConfiguredLink = () => link;

import { ReactNode, useCallback, useContext, useEffect, createContext } from 'react';

import { noop } from 'components/utils';

import { CustomEventNames, EventTypes } from './constants';
import { init } from './init';
import useAuth from '../../hooks/use-auth';
export interface IBaseLogCustomerChangeEventOptions {
  customerId: string;
  customerEmail: string;
}
export interface ILogCustomerDobChangedEventOptions extends IBaseLogCustomerChangeEventOptions {
  previousDob: string;
  newDob: string;
}

export interface ILogCustomerNameChangedEventOptions extends IBaseLogCustomerChangeEventOptions {
  previousName: string;
  newName: string;
}

export interface ILogPersonalizedOfferAssignedEventOptions {
  email: string;
  customerId: string;
  loyaltyId: string;
  offerTemplateId: string;
  offerTemplateName: string;
  reason: string;
  comments?: string;
  orderId?: string;
}

interface IMParticleContext {
  updateUserIdentities(options: { email: string; customerId: string; callback: () => void }): void;
  logCustomerDobChangedEvent(options: ILogCustomerDobChangedEventOptions): void;
  logCustomerNameChangedEvent(options: ILogCustomerNameChangedEventOptions): void;
  logPersonalizedOfferAssigned(options: ILogPersonalizedOfferAssignedEventOptions): void;
}

const MParticleContext = createContext<IMParticleContext>({
  updateUserIdentities: noop,
  logCustomerDobChangedEvent: noop,
  logCustomerNameChangedEvent: noop,
  logPersonalizedOfferAssigned: noop,
});

/**
 * Provides mParticle utilities
 */
export const useMParticleContext = () => useContext(MParticleContext);

/**
 * Provides mParticle utilities
 */
export const MParticleProvider = (props: { children: ReactNode }) => {
  const { email: userEmail, cognitoId: supportAgentId } = useAuth();

  useEffect(() => {
    init();
  }, []);

  const logCustomerDobChangedEvent = ({
    previousDob,
    newDob,
    customerId,
    customerEmail,
  }: ILogCustomerDobChangedEventOptions) => {
    const logAction = () =>
      window.mParticle.logEvent(CustomEventNames.UPDATE_USER_ATTRIBUTES, EventTypes.Other, {
        'Previous DOB': previousDob,
        'New DOB': newDob,
        'Customer ID': customerId,
        'Performed By': userEmail,
      });

    updateUserIdentities({
      customerId,
      email: customerEmail,
      callback: logAction,
    });
  };

  const logCustomerNameChangedEvent = ({
    previousName,
    newName,
    customerId,
    customerEmail,
  }: ILogCustomerNameChangedEventOptions) => {
    const logAction = () =>
      window.mParticle.logEvent(CustomEventNames.UPDATE_USER_ATTRIBUTES, EventTypes.Other, {
        'Previous Name': previousName,
        'New Name': newName,
        'Customer ID': customerId,
        'Performed By': userEmail,
      });

    updateUserIdentities({
      customerId,
      email: customerEmail,
      callback: logAction,
    });
  };

  const updateUserIdentities = ({
    email,
    customerId,
    callback,
  }: {
    email: string;
    customerId: string;
    callback: () => void;
  }) => {
    window.mParticle.Identity.login(
      { userIdentities: { email, customerid: customerId || '' } },
      callback,
    );
  };

  /**
   * Log Personalized Offer Asignment to mParticle
   */
  const logPersonalizedOfferAssigned = useCallback(
    ({
      email,
      customerId,
      loyaltyId,
      offerTemplateId,
      offerTemplateName,
      reason,
      comments,
    }: ILogPersonalizedOfferAssignedEventOptions) => {
      const logAction = () =>
        window.mParticle.logEvent(CustomEventNames.PERSONALIZED_OFFER_ASSIGNED, EventTypes.Other, {
          'Customer Email': email,
          'Customer ID': customerId,
          'Customer Loyalty ID': loyaltyId,
          'Offer Template ID': offerTemplateId,
          'Offer Template Name': offerTemplateName,
          'Remediation Reason': reason,
          'Support Agent ID': supportAgentId,
          'Support Agent Comments': comments,
        });

      updateUserIdentities({
        email,
        customerId,
        callback: logAction,
      });
    },
    [supportAgentId],
  );

  return (
    <MParticleContext.Provider
      value={{
        updateUserIdentities,
        logCustomerDobChangedEvent,
        logCustomerNameChangedEvent,
        logPersonalizedOfferAssigned,
      }}
    >
      {props.children}
    </MParticleContext.Provider>
  );
};

import { useEffect } from 'react';

import { Fastfood } from '@material-ui/icons';
import { Box } from '@mui/system';

import { UseUniversalSearchContext } from 'state/universal-search';

import { useNoDataStyles } from './styles';

const NoData = () => {
  const classes = useNoDataStyles();
  const { setFocused } = UseUniversalSearchContext();

  useEffect(() => {
    setFocused(true);
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setFocused(false);
    }, 300);
    return () => clearTimeout(timer);
  }, [setFocused]);

  return (
    <Box sx={{ minWidth: 275 }} className={classes.box}>
      <Fastfood className={classes.placeholderIcon} />
    </Box>
  );
};

export { NoData };

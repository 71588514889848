import { TableRow, TableCell } from '@material-ui/core';

import CopyableText from 'components/copyable-text';
import DummyText from 'components/dummy-text';
import { EMPTYDATA } from 'components/utils/constants';

import { twoColumnTableProps } from './types';

const TwoColumnTableRow = ({
  headerText,
  headerBold = true,
  loading = false,
  empty = false,
  value,
  fallback = <>{EMPTYDATA}</>,
  copyable = false,
  multiline = false,
  className = '',
}: twoColumnTableProps) => {
  return (
    <TableRow>
      {headerBold ? (
        <TableCell component="th" scope="row">
          {headerText}
        </TableCell>
      ) : (
        <TableCell scope="row">{headerText}</TableCell>
      )}

      <TableCell
        style={{
          overflow: multiline ? 'unset' : 'hidden',
          whiteSpace: multiline ? 'unset' : 'nowrap',
          textOverflow: multiline ? 'unset' : 'ellipsis',
        }}
        className={className}
      >
        {!!loading ? (
          <DummyText />
        ) : copyable ? (
          <CopyableText annotation={headerText} text={value} />
        ) : empty ? (
          fallback
        ) : (
          value
        )}
      </TableCell>
    </TableRow>
  );
};

export default TwoColumnTableRow;

import gql from 'graphql-tag';

const storeHours = gql`
  {
    friday {
      open
      close
    }
    monday {
      open
      close
    }
    sunday {
      open
      close
    }
    saturday {
      open
      close
    }
    tuesday {
      open
      close
    }
    thursday {
      open
      close
    }
    wednesday {
      open
      close
    }
  }
`;

export const GetStore = gql`
  query Store($storeId: String!) {
    store(storeId: $storeId) {
      id: storeNumber
      amenities {
        name {
          _type
          en
        }
      }
      storeNumber
      storeAddress {
        address1
        address2
        city
        country
        state
        stateProvince
        stateProvinceShort
        postalCode
      }
      deliveryHours ${storeHours}
      diningRoomHours ${storeHours}
      driveThruHours ${storeHours}
      franchiseGroupId
      franchiseGroupName
      latitude
      longitude
      phoneNumber
      posVendor
      status
      hasCatering
      hasDelivery
      hasDineIn
      hasDriveThru
      hasMobileOrdering
      hasTakeOut
      lastActiveDateTime
      mobileOrderingStatus
      timezone
    }
  }
`;

export const RELEVANT_ORDER_ORDER_STATUSES = [
  'CATERING_DECLINED',
  'CATERING_PLACED',
  'CATERING_SUCCESSFUL',
  'INSERT_SUCCESSFUL',
  'PAYMENT_SUCCESSFUL',
  'REFUND_ERROR',
  'REFUND_REQUESTED',
  'REFUND_SUCCESSFUL',
  'UPDATE_SUCCESSFUL',
  'VALIDATION_ERROR',
];

export const RELEVANT_ORDER_PAYMENT_STATUSES = [
  'AUTHORIZATION_SUCCESS',
  'CAPTURE_SUCCESSFUL',
  'CANCEL_ERROR',
  'CANCEL_REQUESTED',
  'CANCEL_SUCCESSFUL',
  'PAYMENT_SUCCESSFUL',
  'REFUND_ERROR',
  'REFUND_REQUESTED',
  'REFUND_SUCCESSFUL',
  'VOID_ERROR',
  'VOID_REQUESTED',
  'VOID_SUCCESSFUL',
];

export const FULL_REFUND_DELIVERY_STATUSES = ['ORDER_CANCELLED'];

export const PAYMENT_VERSION_MAPPING = {
  V0: 0,
  V1: 1,
};

export const REFUND_ORDER_STATUSES = ['REFUND_REQUESTED', 'REFUND_SUCCESSFUL'];

export const REFUND_PAYMENT_STATUSES = [
  'REFUND_ERROR',
  'REFUND_REQUESTED',
  'REFUND_SUCCESSFUL',
  'VOID_SUCCESSFUL',
];

export const SECONDARY_IDENTIFIER_TYPES = {
  LOYALTY_12_DIGIT_SWIPE_CARD: 'LOYALTY_12_DIGIT_SWIPE_CARD',
  PAN_TOKEN: 'PAN_TOKEN',
  PHONE_NUMBER: 'PHONE_NUMBER',
};

export const AUTO_FULL_REFUND_LIMIT = 0;

export const AUTO_PARTIAL_REFUND_LIMIT = 1;

export const DEFAULT_LIMIT_FOR_ORDERS = 100;

export const OFFERS_V2_LAUNCH_DARKLY_FLAG_NAME = 'enable-loyalty-standard-offers';

export const OFFERS_VERSIONS = {
  V1: 'v1',
  V2: 'v2',
};

export const NO_PRODUCT_ID = 'NO_PRODUCT_ID';

export const ENVIRONMENT = process.env.NODE_ENV;

//Pulled from ../../src/utils/index.js
export const EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

//Pulled from https://regex101.com/r/j48BZs/2
export const PHONE_REGEX = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;

import { createContext, useContext, ReactNode, useState, useMemo, useCallback } from 'react';

interface IDefaultContext {
  params: Record<string, string>;
  updateParams: (params: Record<string, string | undefined>) => void;
  clearParams: () => void;
}

const defaultContext: IDefaultContext = {
  params: {},
  updateParams: () => {},
  clearParams: () => {},
};

export const ParamsContext = createContext(defaultContext);
export const useParamsContext = () => useContext(ParamsContext);

export const ParamsProvider = ({ children }: { children: ReactNode }) => {
  const [rawParams, setParams] = useState<Record<string, string>>({});
  const stringifiedParams = JSON.stringify(rawParams);
  const params = useMemo(() => JSON.parse(stringifiedParams), [stringifiedParams]);

  const updateParams = useCallback(
    (newParams: Record<string, string | undefined>) => {
      setParams({ ...params, ...newParams });
    },
    [params],
  );
  const clearParams = useCallback(() => setParams({}), []);
  const value = useMemo(
    () => ({ params, updateParams, clearParams }),
    [params, updateParams, clearParams],
  );
  return <ParamsContext.Provider value={value}>{children}</ParamsContext.Provider>;
};

export default ParamsProvider;

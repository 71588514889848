import gql from 'graphql-tag';

export const CtgLoyaltyAssignPersonalizedOffer = gql`
  mutation ctgLoyaltyCreatePersonalizedOffer(
    $loyaltyId: String!
    $offerTemplateId: String!
    $offerConfigId: String!
    $cognitoId: String!
    $reason: String!
    $orderId: String
    $comments: String
  ) {
    ctgLoyaltyCreatePersonalizedOffer(
      loyaltyId: $loyaltyId
      offerTemplateId: $offerTemplateId
      offerConfigId: $offerConfigId
      cognitoId: $cognitoId
      reason: $reason
      orderId: $orderId
      comments: $comments
    ) {
      configId
      id
      loyaltyId
      offerTemplateId: sourceId
    }
  }
`;

/** Defines keys to be used for mParticle reporting
 * The keys will be used to group messages so it's important to be consistent
 * when calling an error or custom event
 *
 * Add new actions here
 */
export enum CustomEventNames {
  UPDATE_USER_ATTRIBUTES = 'Update User Attributes',
  PERSONALIZED_OFFER_ASSIGNED = 'Personalized Offer Assigned',
}

/**
 * mParticle event types
 */
export enum EventTypes {
  Navigation = 1,
  Location = 2,
  Search = 3,
  Transaction = 4,
  UserContent = 5,
  UserPreference = 6,
  Social = 7,
  Other = 8,
}

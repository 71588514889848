import gql from 'graphql-tag';

export const GetReleaseNotes = gql`
  query ReleaseNotes($limit: Int, $offset: Int) {
    ReleaseNotes(limit: $limit, offset: $offset) {
      _id
      releaseDate
      title
      releaseNotes {
        ... on BlockContentBody {
          _key
          _type
          children {
            _key
            _type
            marks
            text
          }
          level
          listItem
          markDefs {
            _key
            _type
            href
          }
          style
        }
        ... on BlockContentImage {
          _key
          _type
          caption
          imageUrl
          lqip
        }
      }
    }
  }
`;

export const GetReleaseNoteByDate = gql`
  query ReleaseNoteByDate($date: String!) {
    ReleaseNoteByDate(date: $date) {
      _id
      releaseDate
      title
      releaseNotes {
        ... on BlockContentBody {
          _key
          _type
          children {
            _key
            _type
            marks
            text
          }
          level
          listItem
          markDefs {
            _key
            _type
            href
          }
          style
        }
        ... on BlockContentImage {
          _key
          _type
          caption
          imageUrl
          lqip
        }
      }
    }
  }
`;

import { alpha, createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 'auto',
      width: '100%',
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('sm')]: {
        width: '35ch',
        marginLeft: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {
        width: '45ch',
        '&:focus-within': {
          width: '55ch',
        },
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    searchResults: {
      position: 'absolute',
      background: theme.palette.background.paper,
      top: 35,
      left: 0,
      right: 0,
      borderRadius: 2,
      color: theme.palette.text.primary,
      maxHeight: theme.spacing(50),
      overflowY: 'auto',
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      width: '100%',
    },
    searchLabel: {
      color: theme.palette.grey[500],
      margin: '10px',
      display: 'block',
      userSelect: 'none',
    },
  });
});

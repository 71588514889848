import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { noop } from 'components/utils';

export type AuthProps = {
  loading?: boolean;
  email: string;
  cognitoId: string;
  needsReset: boolean;
  userSession:
    | CognitoUserSession
    | { totpRequired: boolean; totpSecret?: string; shouldChangePassword?: boolean }
    | null;
  roles: string[];
  permissions: string[];
  limits: {
    points: number;
    refundAmount: number;
    refundDays: number;
    loyaltyPointsIssuanceAmount: number;
  };
  changePassword({ newPassword }: { newPassword: string }): Promise<unknown>;
  getCurrentSession(): Promise<CognitoUserSession | null>;
  isAuthenticated(): boolean;
  signIn({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<
    | CognitoUserSession
    | { totpRequired: boolean; totpSecret?: string; shouldChangePassword?: boolean }
  >;
  signOut(): Promise<void>;
  resetPassword({
    username,
    verificationCode,
    newPassword,
  }: {
    username: string;
    verificationCode: string;
    newPassword: string;
  }): Promise<void>;
  getResetPasswordCode({ username }: { username: string }): Promise<void>;
  verifyTOTPForAssociation({ token }: { token: string }): void;
  verifyTOTPForSignIn({ token }: { token: string }): void;
};

export const DefaultAuthProps: AuthProps = {
  email: '',
  cognitoId: '',
  needsReset: false,
  userSession: null,
  roles: [],
  permissions: [],
  limits: { points: 0, refundAmount: 0, refundDays: 0, loyaltyPointsIssuanceAmount: 0 },
  changePassword: ({ newPassword }: { newPassword: string }) => {
    return new Promise((_resolve, _reject) => {
      return null;
    });
  },
  getCurrentSession: () => {
    return new Promise((_resolve, _reject) => {
      return null;
    });
  },
  isAuthenticated: () => false,
  signIn: ({ email, password }: { email: string; password: string }) => {
    return new Promise((_resolve, _reject) => {
      return { totpRequired: true, totpSecret: '', shouldChangePassword: true };
    });
  },
  signOut: () => {
    return new Promise((_resolve, _reject) => {
      noop();
    });
  },
  resetPassword: ({
    username,
    verificationCode,
    newPassword,
  }: {
    username: string;
    verificationCode: string;
    newPassword: string;
  }) => {
    return new Promise((_resolve, _reject) => {
      noop();
    });
  },
  getResetPasswordCode: ({ username }: { username: string }) => {
    return new Promise((_resolve, _reject) => {
      noop();
    });
  },
  verifyTOTPForAssociation: ({ token }: { token: string }) => {
    noop();
  },
  verifyTOTPForSignIn: ({ token }: { token: string }) => {
    noop();
  },
  loading: true,
};
/**
 * This type holds the return values for AWS IAuthenticationCallbacks
 * AWS does not provide return values for these callbacks.
 */
export type AuthFlags = {
  totpRequired: boolean;
  totpSecret?: string;
  shouldChangePassword?: boolean;
};

export function isCognitoUserSession(
  session: CognitoUserSession | AuthFlags,
): session is CognitoUserSession {
  if ('isValid' in session) {
    return session.isValid();
  }
  return false;
}

import { setContext } from 'apollo-link-context';
import { merge } from 'lodash-es';

import { getCurrentUserSession } from 'remote/auth';

export const withAuthToken = setContext(async (_, previousContext) => {
  const session = await getCurrentUserSession();
  if (!session) {
    return previousContext;
  }

  const isFederatedSignIn = session
    .getIdToken()
    .decodePayload()
    .identities?.some((id: { providerName: string }) => id.providerName === 'Okta');

  const headers = {
    authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
    ...(isFederatedSignIn && { isFederatedSignIn }),
  };

  return merge({ headers }, previousContext);
});

// import { isProduction } from 'utils/environment';
import { IMParticle } from './types';
import { isProduction, MPARTICLE_API_KEY } from '../../constants';

declare global {
  interface Window {
    /**
     * mParticle client
     */
    mParticle: IMParticle;
  }
}

/**
 * Initializes mParticle
 *
 * Downloads mParticle .js from cdn and initializes the web client.
 */
export const init = (callback: Function = () => {}) => {
  window.mParticle = {
    //@ts-ignore this object seems to change after initialization
    config: {
      isDevelopmentMode: !isProduction,
      logLevel: isProduction ? 'none' : 'warn',
    },
  };

  mParticleInit(MPARTICLE_API_KEY, callback);
};

/**
 * mParticle enqueue code that downloads and initializes mParticle.
 */
const mParticleInit = (t: string, callback: Function) => {
  window.mParticle = window.mParticle || {
    EventType: {
      Unknown: 0,
      Navigation: 1,
      Location: 2,
      Search: 3,
      Transaction: 4,
      UserContent: 5,
      UserPreference: 6,
      Social: 7,
      Other: 8,
    },
  };
  // @ts-ignore
  window.mParticle.eCommerce = { Cart: {} };
  // @ts-ignore
  window.mParticle.Identity = {};
  // @ts-ignore
  window.mParticle.config = window.mParticle.config || {};
  // @ts-ignore
  window.mParticle.config.rq = [];
  // @ts-ignore
  window.mParticle.ready = function (t) {
    // @ts-ignore
    window.mParticle.config.rq.push(t);
  };
  // @ts-ignore
  function e(e, o) {
    return function () {
      if (o) {
        e = o + '.' + e;
      }
      var t = Array.prototype.slice.call(arguments);
      t.unshift(e);
      // @ts-ignore

      window.mParticle.config.rq.push(t);
    };
  }
  var o = [
    'endSession',
    'logError',
    'logEvent',
    'logForm',
    'logLink',
    'logPageView',
    'setSessionAttribute',
    'setAppName',
    'setAppVersion',
    'setOptOut',
    'setPosition',
    'startNewSession',
    'startTrackingLocation',
    'stopTrackingLocation',
  ];
  var n = ['setCurrencyCode', 'logCheckout'];
  var i = ['identify', 'login', 'logout', 'modify'];
  o.forEach(function (t) {
    // @ts-ignore
    window.mParticle[t] = e(t);
  });
  n.forEach(function (t) {
    window.mParticle.eCommerce[t] = e(t, 'eCommerce');
  });
  i.forEach(function (t) {
    window.mParticle.Identity[t] = e(t, 'Identity');
  });
  var r = document.createElement('script');
  r.type = 'text/javascript';
  r.async = true;
  r.src =
    ('https:' === document.location.protocol ? 'https://jssdkcdns' : 'http://jssdkcdn') +
    '.mparticle.com/js/v2/' +
    t +
    '/mparticle.js';
  r.id = 'mparticle-script-tag';
  // @ts-ignore
  r.onload = callback;

  var c = document.getElementsByTagName('script')[0];
  // @ts-ignore
  c.parentNode.insertBefore(r, c);
};

import gql from 'graphql-tag';

export const CTGLoyaltyUserFragment = gql`
  fragment CTGLoyaltyUserFragment on CTGLoyaltyUser {
    id
    points
    createdAt
    email
    name
    dateOfBirth
    cognitoId
  }
`;

export const DEFAULT = '#eff1f5';
export const WHITE = '#fff';
export const BACKGROUND_ACCENT = '#F9F9F9';
export const BACKGROUND_SECONDARY = '#FAFAFA';
export const GREY = '#C4C4C4';
export const SECONDARY_GREY = '#4a4a4a';
export const LIGHT_GREY = '#F0F0F0';
export const DARK_GREY = '#838383';
export const ACTIVE_GREY = '#545454';
export const PRIMARY_ACCENT = '#add8e6';
export const PRIMARY_BUTTON = '#59bec9';
export const LIGHT_RED = '#d24359';
export const SECONDARY_RED = '#FF4136';
export const RED = '#c8102e';
export const DARK_RED = '#af0622';
export const BEIGE = '#F9F5EE';
export const LIGHT_GREEN = '#00FF70';
export const DARK_BLUE = '#0174D9';
export const FIREHOUSE_SUBS_BACKGROUND = '#F2F2F6';
export const FIREHOUSE_SUBS_RED = '#b11310';
export const FIREHOUSE_SUBS_DARK_RED = '#b11310';

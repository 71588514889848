import React from 'react';

import { Backspace } from '@material-ui/icons';
import { ListItem } from 'design-system';

export const SearchError = ({ searchTerm, setSearchTerm, inputRef }: any) => {
  const noResults = (
    <>
      No results for <b>'{searchTerm}'</b>
    </>
  );
  return (
    <ListItem
      button
      onClick={(event) => {
        event.preventDefault();
        setSearchTerm('');
        inputRef?.current?.focus();
      }}
      iconTooltipText="Clear"
      icon={<Backspace />}
      text={noResults}
    />
  );
};

import { useCallback, useState } from 'react';

import { useApolloClient } from '@apollo/react-hooks';
import { QueryOptions } from 'apollo-client';

import {
  GetCTGLoyaltyIdByEmail,
  GetCTGLoyaltyUserById,
  CTGLoyaltyIssuePoints,
  GetCTGLoyaltyTransactionsLegacy,
} from 'remote/queries';
import { IsoCountryCode2Char } from 'types';
import { ICtgLoyaltyTransaction } from 'types/ctg-loyalty';

export type CTGLoyaltyUser = {
  id: string;
  points: number;
  createdAt: string;
  email: string;
  name?: string;
  dateOfBirth?: string;
  cognitoId?: string;
  loyaltyLegacyId?: string;
};

export interface ICTGLoyaltyContext {
  loading: boolean;
  error: string;
  loyaltyMarket: IsoCountryCode2Char;
  setLoyaltyMarket: (market: IsoCountryCode2Char) => void;
  user?: CTGLoyaltyUser;
  userTransactions?: ICtgLoyaltyTransaction[];
  fetchLoyaltyUserByEmail: (email: string) => Promise<CTGLoyaltyUser | undefined>;
  fetchLoyaltyUserById: (id: string) => Promise<CTGLoyaltyUser | undefined>;
  fetchLoyaltyTransactions: (id: string) => Promise<ICtgLoyaltyTransaction[]>;
  issuePoints: (
    loyaltyId: string,
    loyaltyUserCognitoId: string,
    points: number,
    reason: string,
    orderId?: string,
  ) => Promise<number | undefined>;
}

type QueryClientOptions = Pick<QueryOptions, 'query' | 'variables' | 'fetchPolicy' | 'context'> & {
  path: string;
  errorMsg: string;
};

const useCTGLoyalty = (): ICTGLoyaltyContext => {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [user, setUser] = useState<CTGLoyaltyUser | undefined>();
  const [userTransactions, setUserTransactions] = useState<ICtgLoyaltyTransaction[]>([]);
  const [loyaltyMarket, setMarket] = useState<IsoCountryCode2Char>(IsoCountryCode2Char.US);

  const queryClient = useCallback(
    async <T>({
      query,
      variables,
      errorMsg,
      path,
      fetchPolicy = 'cache-first',
    }: QueryClientOptions): Promise<T | undefined> => {
      setLoading(true);
      let result: T | undefined;
      try {
        const { data, errors } = await client.query({
          query,
          variables,
          errorPolicy: 'all',
          fetchPolicy,
          context: {
            headers: {
              'x-region': loyaltyMarket,
            },
          },
        });
        result = data && data[path];
        if (!result || errors) {
          setError(errorMsg);
        } else {
          setError('');
        }
      } catch (err) {
        setError(errorMsg);
      }
      setLoading(false);
      return result;
    },
    [client, loyaltyMarket],
  );

  const fetchLoyaltyUserById = useCallback(
    async (loyaltyId: string) => {
      const result = await queryClient<CTGLoyaltyUser>({
        query: GetCTGLoyaltyUserById,
        variables: { loyaltyId },
        errorMsg: 'Failed to fetch loyalty user',
        path: 'ctgLoyaltyUserById',
        fetchPolicy: 'network-only',
      });
      setUser(result);
      return result;
    },
    [queryClient],
  );

  const fetchLoyaltyUserByEmail = useCallback(
    async (email: string) => {
      const loyaltyId = await queryClient<string>({
        query: GetCTGLoyaltyIdByEmail,
        variables: { email },
        errorMsg: 'No loyalty account found for email',
        path: 'ctgLoyaltyIdByEmail',
      });
      if (loyaltyId) {
        return fetchLoyaltyUserById(loyaltyId);
      }
    },
    [queryClient, fetchLoyaltyUserById],
  );

  const fetchLoyaltyTransactions = useCallback(
    async (loyaltyId: string) => {
      const results = await queryClient<ICtgLoyaltyTransaction[]>({
        query: GetCTGLoyaltyTransactionsLegacy,
        variables: { loyaltyId },
        errorMsg: 'Failed to fetch loyalty user transactions',
        path: 'ctgLoyaltyTransactions',
      });
      const transactions = results || [];
      setUserTransactions(transactions);
      return transactions;
    },
    [queryClient],
  );

  const issuePoints = useCallback(
    async (
      loyaltyId: string,
      loyaltyUserCognitoId: string,
      points: number,
      reason: string,
      orderId?: string,
    ) => {
      const { data } = await client.mutate({
        mutation: CTGLoyaltyIssuePoints,
        variables: { loyaltyId, loyaltyUserCognitoId, points, reason, orderId },
      });
      const dataPath = 'ctgLoyaltyIssuePoints';
      const result: number | undefined = (data && data[dataPath]) || undefined;
      if (result && user) {
        const newPoints = user.points + result;
        setUser({
          ...user,
          points: newPoints,
        });
        return newPoints;
      }
    },
    [client, user],
  );

  const setLoyaltyMarket = (market: IsoCountryCode2Char) => setMarket(market);

  return {
    loyaltyMarket,
    setLoyaltyMarket,
    loading,
    error,
    user,
    userTransactions,
    issuePoints,
    fetchLoyaltyUserByEmail,
    fetchLoyaltyUserById,
    fetchLoyaltyTransactions,
  };
};

export default useCTGLoyalty;

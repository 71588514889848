import gql from 'graphql-tag';

export const CTGLoyaltyOffers = gql`
  query ctgLoyaltyOffersByLoyaltyId($loyaltyId: String!) {
    ctgLoyaltyOffersByLoyaltyId(loyaltyId: $loyaltyId) {
      id
      name
      sourceId
      createdAt
      metadata {
        source
        remediationReason
        comments
        agentEmail
        orderId
      }
      endDate
    }
  }
`;

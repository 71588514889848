import gql from 'graphql-tag';

export const CTGLoyaltyTransactionFragmentLegacy = gql`
  fragment CTGLoyaltyTransactionFragmentLegacy on CTGLoyaltyTransaction {
    shortId
    status
    loyaltyId
    channel
    pointsEarned
    bonusPointsEarned
    pointsUsed
    createdAt
    total
    currency
    rbiOrderId
    rewardIds
    pointsDeducted
    additionalReferenceIds {
      id
      type
    }
    transactionDetails {
      promotions {
        id
        metadata {
          agentEmail
          comments
          createdAt
          externalIdentifiers {
            value
            type
          }
          maxTransactionLimitReached
          name
          orderId
          remediationReason
          source
        }
        type
      }
    }
  }
`;

export const CTGLoyaltyTransactionFragmentForTimeline = gql`
  fragment CTGLoyaltyTransactionFragmentForTimeline on CTGLoyaltyTransaction {
    additionalReferenceIds {
      id
      type
    }
    id
    channel
    createdAt
    currency
    metadata {
      agentEmail
      comments
      remediationReason
      identifierType
    }
    pointsDeducted
    pointsEarned
    pointsUsed
    rbiOrderId
    shortId
    storeId
    store {
      timezone
      storeAddress {
        country
      }
    }
    total
  }
`;

export const CTGLoyaltyTransactionFragmentForSingleOrder = gql`
  fragment CTGLoyaltyTransactionFragmentForSingleOrder on CTGLoyaltyTransaction {
    additionalReferenceIds {
      id
      type
    }
    transactionDetails {
      promotions {
        id
        metadata {
          name
        }
      }
    }
    metadata {
      maxTransactionLimitReached
    }
    rbiOrderId
  }
`;

export const CTGLoyaltyTransactionFragmentForDetailView = gql`
  fragment CTGLoyaltyTransactionFragmentForDetailView on CTGLoyaltyTransaction {
    shortId
    status
    loyaltyId
    channel
    pointsEarned
    bonusPointsEarned
    pointsUsed
    createdAt
    total
    currency
    rbiOrderId
    rewardIds
    pointsDeducted
    additionalReferenceIds {
      id
      type
    }
    storeId
    store {
      storeAddress {
        address1
        address2
        city
        stateProvince
        country
      }
      name
      timezone
    }
    metadata {
      agentEmail
      identifierType
    }
    transactionDetails {
      person {
        currentPoints
      }
      payments {
        type
        amount
      }
      orders {
        lineId
        parentReferenceId
        plu
        productId
        price
        quantity
        pluDocuments {
          name
        }
      }
      promotions {
        id
        metadata {
          name
        }
      }
      fulfillment {
        type
      }
      posVendor {
        type
      }
    }
  }
`;

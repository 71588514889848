import gql from 'graphql-tag';

import { CTGLoyaltyUserFragment } from './fragments';

export const GetCTGLoyaltyUserById = gql`
  query ctgLoyaltyUserById($loyaltyId: String!) {
    ctgLoyaltyUserById(loyaltyId: $loyaltyId) {
      ...CTGLoyaltyUserFragment
    }
  }
  ${CTGLoyaltyUserFragment}
`;

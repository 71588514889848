import gql from 'graphql-tag';

export const CartEntryFragment = gql`
  fragment CartEntryFragment on CartEntries {
    name
    pickerSelections
    price
    quantity
    sanityId
    type
    url
    productHierarchy {
      L1
      L2
      L3
      L4
      L5
    }
  }
`;

// consider breaking this up into smaller fragments
export const CartFragment = gql`
  fragment CartFragment on Cart {
    cartEntries {
      ...CartEntryFragment
      children {
        ...CartEntryFragment
        children {
          ...CartEntryFragment
          children {
            ...CartEntryFragment
            children {
              ...CartEntryFragment
              children {
                ...CartEntryFragment
              }
            }
          }
        }
      }
    }
    customerName
    discounts {
      name
      value
    }
    donations {
      id
      name
      totalCents
    }
    payment {
      cardType
      ccExpiryMonth
      ccExpiryYear
      ccLast4
      fdCorrelationId
      fdCustomerId
      fdSaleId
    }
    serviceMode
    storeAddress {
      addressLine1
      addressLine2
      city
      country
      zip
      state
    }
    storeId
    storeDetails {
      franchiseGroupName
    }
    subTotalCents
    subTotalPaidCents
    taxCents
    taxPaidCents
    ticketNumber
    totalCents
    totalPaidCents
    userDetails {
      createdAt
      dob
      email
      isoCountryCode
      name
      phoneNumber
      promotionalEmails
      zip
    }
    userId
  }
  ${CartEntryFragment}
`;

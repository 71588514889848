import gql from 'graphql-tag';

import {
  CTGLoyaltyTransactionFragmentLegacy,
  CTGLoyaltyTransactionFragmentForTimeline,
  CTGLoyaltyTransactionFragmentForDetailView,
  CTGLoyaltyTransactionFragmentForSingleOrder,
} from './fragments';

// Used for legacy loyalty tab view
export const GetCTGLoyaltyTransactionsLegacy = gql`
  query ctgLoyaltyTransactions($loyaltyId: String!) {
    ctgLoyaltyTransactions(loyaltyId: $loyaltyId) {
      ...CTGLoyaltyTransactionFragmentLegacy
    }
  }
  ${CTGLoyaltyTransactionFragmentLegacy}
`;

// Used for showing loyalty transactions on the orders timeline and related information mentioned below
// For a scan and pay order, fetches total, currency, points earned, points used and created date
// For a support transaction, fetches points earned, support agent email and created date
export const GetCTGLoyaltyTransactionsForTimeline = gql`
  query ctgLoyaltyTransactions($loyaltyId: String!, $resumeAfter: String) {
    ctgLoyaltyTransactions(loyaltyId: $loyaltyId, resumeAfter: $resumeAfter) {
      ...CTGLoyaltyTransactionFragmentForTimeline
    }
  }
  ${CTGLoyaltyTransactionFragmentForTimeline}
`;

// Used for showing all the details on single scan and pay order page
export const GetCTGLoyaltyTransaction = gql`
  query ctgLoyaltyTransaction($loyaltyId: String!, $shortId: String!) {
    ctgLoyaltyTransaction(loyaltyId: $loyaltyId, shortId: $shortId) {
      ...CTGLoyaltyTransactionFragmentForDetailView
    }
  }
  ${CTGLoyaltyTransactionFragmentForDetailView}
`;

// Used for showing promotions on single order page
export const GetCTGLoyaltyTransactionsForSingleOrder = gql`
  query ctgLoyaltyTransactions($loyaltyId: String!) {
    ctgLoyaltyTransactions(loyaltyId: $loyaltyId) {
      ...CTGLoyaltyTransactionFragmentForSingleOrder
    }
  }
  ${CTGLoyaltyTransactionFragmentForSingleOrder}
`;

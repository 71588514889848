import { ListAlt, LocalMall, LocalOffer, People, Redeem, Stars, Store } from '@material-ui/icons';

import Logo from 'components/logos/index';
import { FIREHOUSE_SUBS_DARK_RED } from 'state/theme/colors';

import { IBrandConfigs } from './brand-config';
import { routes } from '../utils/routing';

const fhsConfigs: IBrandConfigs = {
  links: [
    {
      name: 'Customers',
      route: routes.customer,
      Icon: <People />,
    },
    {
      name: 'Orders',
      route: routes.orders,
      Icon: <LocalMall />,
    },
    {
      name: 'Support History',
      route: routes.supportHistory,
      Icon: <ListAlt />,
    },
    {
      name: 'Gift Cards',
      route: routes.giftCard,
      Icon: <Redeem />,
    },
    {
      name: 'Offers',
      route: routes.offersLD,
      Icon: <LocalOffer />,
    },
    {
      name: 'Loyalty',
      route: routes.ctgLoyalty,
      Icon: <Stars />,
    },
    {
      name: 'Stores',
      route: routes.store,
      Icon: <Store />,
    },
  ],
  Logo: Logo,
  theme: {
    brandColor: FIREHOUSE_SUBS_DARK_RED,
  },
  ctgLoyaltyEnabled: true,
  hasPhysicalCtgLoyaltyCard: false,
  loyaltyPointsPerCurrency: {
    USA: 1000,
    CAN: 1000,
    DEFAULT: 1000,
  },
  allowPointsOnLoyaltyTab: {
    USA: true,
    CAN: true,
    DEFAULT: true,
  },
};

export default fhsConfigs;

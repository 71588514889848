import gql from 'graphql-tag';

// Each query in this module must return at least an id and a __typename

export const lookupCustomerByEmail = gql`
  query Customer($email: String!) {
    Customer(email: $email) {
      id: customerId
      __typename
      email
      isoCountryCode
      offersVersion
    }
  }
`;

export const lookupCustomerByCustomerId = gql`
  query Customer($customerId: String!) {
    Customer(customerId: $customerId) {
      id: customerId
      __typename
      email
      isoCountryCode
      offersVersion
    }
  }
`;

export const lookupOrderById = gql`
  query Order($rbiOrderId: String!) {
    Order(rbiOrderId: $rbiOrderId) {
      id: rbiOrderId
      __typename
      cart {
        cartEntries {
          name
        }
      }
      userId
      customer {
        id: customerId
        __typename
        email
        isoCountryCode
        offersVersion
      }
    }
  }
`;

export const searchStores = gql`
  query stores($searchTerm: String!) {
    stores(searchTerm: $searchTerm) {
      id: _id
      __typename
      storeAddress {
        address1
        address2
        city
        state
        postalCode
      }
      storeNumber
    }
  }
`;

export const lookupPrepaidByCardNumber = gql`
  query prepaidByCardNumber($cardNumber: String!) {
    prepaidByCardNumber(
      cardNumber: $cardNumber
      isoCountryCode: "USA" # this value does not affect lookup but must be a valid iso country code
    ) {
      id: cardNumber
      __typename
      cardNumber
      fdAccountId
      transactions {
        currencyCode # this is how we can tell the actual country of the card
      }
    }
  }
`;

export const searchCardId = gql`
  query customerByRewardsCardId($cardId: String!) {
    customerByRewardsCardId(cardId: $cardId) {
      id: customerId
      __typename
      email
      offersVersion
    }
  }
`;

export const universalSearch = gql`
  query universalSearch($searchTerm: String!) {
    Search(searchTerm: $searchTerm) {
      hits
      results {
        ... on Order {
          customer {
            id: customerId
            email
          }
          id: rbiOrderId
        }
        ... on CustomerDetails {
          customerId
          email
          offersVersion
        }
        ... on Store {
          id: _id
          franchiseGroupId
          franchiseGroupName
          storeAddress {
            address1
          }
          storeNumber
        }
      }
    }
  }
`;

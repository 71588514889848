const reduced =
  (Parent, Provider) =>
  ({ children }) =>
    (
      <Parent>
        <Provider>{children}</Provider>
      </Parent>
    );

const init = ({ children }) => children;

const ReduceProviders = ({ providers, children }) => {
  const ReducedProvider = providers.reduce(reduced, init);

  return <ReducedProvider>{children}</ReducedProvider>;
};

export default ReduceProviders;
